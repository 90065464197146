"use client";

import React, {
  PropsWithChildren,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
} from "react";

import Box from "@mui/material/Box";
import { useParams, usePathname } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { GlobalLayoutProps as GlobalLayoutPropsBff } from "~/bff/components/GlobalLayout";
import { BackToTopButton } from "~/components/back-to-top-button/component";
import { AccessibilityLabels } from "~/constants/accessibility";
import { BloomreachPageType } from "~/constants/bloomreach";
import {
  dynamicYieldMetaLinks,
  preconnectClassName,
  preconnectLinkType,
  prefetchClassName,
  prefetchLinkType,
} from "~/constants/dynamic-yield-links";
import { EventNames as NREventNames } from "~/constants/new-relic";
import { AccessibilityLabelsContext } from "~/context/accessibility-label/context";
import { AuthContext } from "~/context/auth/context";
import { BreakpointsProvider } from "~/context/breakpoints/provider";
import { ClickAndCollectInterrupterPanelProvider } from "~/context/click-and-collect-interrupter-panel/provider";
import { DictionaryLabelContext } from "~/context/dictionary-label/context";
import { Dictionary, DictionaryKeys } from "~/context/dictionary-label/types";
import { NotificationProvider } from "~/context/notification/provider";
import { ProductDetailsProvider } from "~/context/product-details/provider";
import { ShoppingBagProvider } from "~/context/shopping-bag/provider";
import { StoreSelectorProvider } from "~/context/store-selector/provider";
import { VariablesContext } from "~/context/variables/context";
import { convertLabelsToProps } from "~/helpers/convert-labels-to-props";
import { addMetaLinkIfNotExists } from "~/helpers/dom-utils/addMetaLinkIfNotExists";
import { removeElementsByClassName } from "~/helpers/dom-utils/removeElementsByClassName";
import { getDataForBloomreachInit } from "~/helpers/get-data-for-bloomreach-init";
import { initBloomreachPixel } from "~/helpers/init-bloomreach-pixel";
import { getCountryCodeFromLocale } from "~/helpers/locales";
import { useIsFunctionalCookiesAccepted } from "~/hooks/use-is-functional-cookies-accepted/hook";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";

export type GlobalLayoutProps = PropsWithChildren<
  GlobalLayoutPropsBff & {
    header: ReactElement;
    footer: ReactElement;
    clickCollectInterrupterPanel: ReactElement;
  }
>;

export const GlobalLayout: React.FC<GlobalLayoutProps> = ({
  colorFilterPalette,
  dictionary,
  filtersMenu,
  header,
  footer,
  productTags,
  children,
  storeSelector,
  clickCollectInterrupterPanel,
  passwordResetSuccessPopUp,
  accessibility,
  countryNames,
}) => {
  const pathname = usePathname();

  const { locale } = useParams<GlobalPathParams>();

  const variables = useContext(VariablesContext);

  const authContext = useContext(AuthContext);

  const buttonsDictionary = useMemo(
    () =>
      dictionary ? (convertLabelsToProps(dictionary) as Dictionary) : undefined,
    [dictionary],
  );

  const labelsAccessibility = useMemo(
    () =>
      accessibility && (convertLabelsToProps(accessibility) as AccessibilityLabels),
    [accessibility],
  );

  const backToTopButtonName = useMemo(
    () => buttonsDictionary?.[DictionaryKeys.BackToTop],
    [buttonsDictionary],
  );

  const brandName = useMemo(
    () => buttonsDictionary?.[DictionaryKeys.BrandName],
    [buttonsDictionary],
  );

  const countryName = useMemo(() => {
    const countryCode = getCountryCodeFromLocale(locale);

    const country = countryNames?.find(
      (countryNameItem) =>
        countryNameItem?.code?.toLowerCase() === countryCode.toLowerCase(),
    );

    return country?.name || "";
  }, [countryNames, locale]);

  productTags?.productTags?.sort((a, b) => {
    if (
      a?.priorityNumber === null ||
      a?.priorityNumber === undefined ||
      b?.priorityNumber === null ||
      b?.priorityNumber === undefined
    ) {
      return 0;
    }
    return a.priorityNumber - b.priorityNumber;
  });

  const isFunctionalCookieAccepted = useIsFunctionalCookiesAccepted();

  useEffect(() => {
    const initBloomreachData = getDataForBloomreachInit(
      locale,
      brandName ?? "",
      pathname,
      // @ts-expect-error: mismatch of types
      children?.[0]?.props ?? undefined,
    );

    const previousPath = localStorage?.getItem("previousPath")?.split(",")[1] || "";
    if (
      initBloomreachData !== null &&
      initBloomreachData?.pType !== BloomreachPageType.ClpPage &&
      initBloomreachData?.pType !== BloomreachPageType.SearchResultsPage &&
      isFunctionalCookieAccepted
    ) {
      initBloomreachPixel(
        {
          ...initBloomreachData,
          user_id: authContext?.account?.uid ?? undefined,
        },
        locale,
        previousPath,
      );
    }
  }, [
    authContext?.account?.uid,
    brandName,
    children,
    isFunctionalCookieAccepted,
    locale,
    pathname,
  ]);

  const isDynamicYieldEnabled = Boolean(
    AzureConfigurator.getConfig(locale)?.featureFlagList?.dynamicYield?.enabled,
  );

  /*
   * This serves as a temporary solution. In an ideal scenario, we would utilize the Head component from Next.js.
   * However, a challenge arises with Next.js appending duplicate tags to the client-side head section,
   * which is unsuitable for our current use case.
   * After conducting an investigation, we have identified that the root cause of this duplication
   * is related to the insertion of a script tag inside the Head component.
   * Specifically, we are inserting a script containing JsonLD (see JsonLD component).
   */

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (typeof window !== "undefined" && isDynamicYieldEnabled) {
      dynamicYieldMetaLinks.forEach((url) =>
        addMetaLinkIfNotExists(url, preconnectClassName, preconnectLinkType),
      );
      dynamicYieldMetaLinks.forEach((url) =>
        addMetaLinkIfNotExists(url, prefetchClassName, prefetchLinkType),
      );
    } else {
      removeElementsByClassName(preconnectClassName);
      removeElementsByClassName(prefetchClassName);
    }
  }, [isDynamicYieldEnabled]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (typeof window !== "undefined") {
      // eslint-disable-next-line no-restricted-globals
      window.onerror = function (event, source, lineno, colno, error) {
        const errorObj = {
          event,
          source,
          lineno,
          colno,
          message: error?.message,
          trace: error?.stack,
        };
        // eslint-disable-next-line no-restricted-globals
        window?.newrelic?.addPageAction(NREventNames.windowError, errorObj);
      };
    }
  }, []);

  return (
    <VariablesContext.Provider
      value={{
        ...variables,
        colorPalette: variables?.colorPalette || colorFilterPalette, //TODO: TECH-DEBT: colorPalette should be refactored in scope of this task  https://team-1626860771808.atlassian.net/browse/OSCWEB-20784
        filtersMenu: filtersMenu?.props,
        productTags: productTags?.productTags,
        passwordResetSuccessPopUp: passwordResetSuccessPopUp?.props,
        countryNames,
        countryName,
      }}
    >
      <BreakpointsProvider>
        <ShoppingBagProvider>
          <ProductDetailsProvider>
            <DictionaryLabelContext.Provider value={buttonsDictionary ?? null}>
              <AccessibilityLabelsContext.Provider
                value={labelsAccessibility ?? null}
              >
                <StoreSelectorProvider storeSelector={storeSelector}>
                  <ClickAndCollectInterrupterPanelProvider>
                    <BackToTopButton name={backToTopButtonName} />
                    <NotificationProvider>
                      {header}
                      <Box component="main" role="main">
                        {children}
                      </Box>
                      {footer}
                    </NotificationProvider>
                    {clickCollectInterrupterPanel}
                  </ClickAndCollectInterrupterPanelProvider>
                </StoreSelectorProvider>
              </AccessibilityLabelsContext.Provider>
            </DictionaryLabelContext.Provider>
          </ProductDetailsProvider>
        </ShoppingBagProvider>
      </BreakpointsProvider>
    </VariablesContext.Provider>
  );
};
